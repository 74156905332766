@font-face {
  font-family: DisketMono-R;
  src: url(../fonts/Disket-Mono-Regular.woff2) format("woff2");
}
@font-face {
  font-family: DisketMono-B;
  src: url(../fonts/Disket-Mono-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GmarketSansBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
