.warp {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.header {
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.header > h1 {
  font-family: "GmarketSansBold";
  font-size: 24px;
  width: 200px;
  color: #fff;
}
