.mouse_cursor {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 999;
}

.mouse_cursor > div {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #fff;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  --cursor-scale: 1;
  transform: scale3d(var(--cursor-scale), var(--cursor-scale), 1);
  will-change: transform;
  transition: transform 150ms ease-in-out, background-color 150ms ease-in-out;
}

.mouse_cursor > div::after {
  content: attr(data-name);
  position: absolute;
  color: #fff;
  transform: scale(0.45);
  white-space: nowrap;
}
