.pofol_wrap {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: start; */
  width: 1700px;
  margin: 0 auto;
}

.pofol_item {
  width: 548px;
  height: 568px;

  background-color: skyblue;
  text-align: center;
  margin-bottom: 2rem;
  margin-right: 28px;
  transition: 0.5s;
  position: relative;
  color: #5c5959;
}

.pofol_item:hover {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
}

.pofol_item:hover .pofolCategori {
  opacity: 0;
}

.pofol_item:hover .pofolTitle {
  transform: scale(1.3);
}

.pofol_item:nth-child(3n) {
  margin-right: 0;
}

/* .pofol_item:nth-child(2),
.pofol_item:nth-child(3) {
  color: #fff;
} */

.pofolCategori {
  position: absolute;
  font-size: 16px;
  bottom: 52px;
  left: 30px;
  text-align: left;
  font-family: "GmarketSansRegular";
}

.pofolTitle {
  position: absolute;
  font-size: 24px;
  bottom: 20px;
  left: 30px;
  transform-origin: 0 100%;
  transition: transform 0.3s;
  font-family: "GmarketSansBold";
}
