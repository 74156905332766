.star_wrap {
  width: 100%;
  height: 1000px;
  /* overflow: hidden; */
  /* position: absolute; */
  background-color: #222;
}

.stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 155px 140px #fff, 1382px 1571px #fff, 1470px 1167px #fff,
    1552px 1253px #fff, 1354px 1623px #fff, 958px 922px #fff, 800px 505px #fff,
    1142px 557px #fff, 575px 1146px #fff, 570px 347px #fff, 1140px 1377px #fff,
    1277px 582px #fff, 1299px 1643px #fff, 1422px 384px #fff, 1556px 998px #fff,
    1789px 1505px #fff, 396px 1573px #fff, 325px 888px #fff, 1279px 568px #fff,
    1783px 96px #fff, 798px 1671px #fff, 373px 200px #fff, 440px 1608px #fff,
    1760px 1854px #fff, 1288px 1905px #fff, 1094px 1075px #fff,
    1811px 1734px #fff, 1198px 551px #fff, 1202px 702px #fff, 725px 1493px #fff,
    1394px 1110px #fff, 629px 820px #fff, 983px 530px #fff, 312px 1011px #fff,
    882px 1381px #fff, 16px 305px #fff, 1810px 644px #fff, 721px 18px #fff,
    1553px 1920px #fff, 324px 559px #fff, 1633px 848px #fff, 631px 849px #fff,
    667px 566px #fff, 1710px 213px #fff, 1028px 1152px #fff, 1336px 479px #fff,
    1413px 1600px #fff, 263px 1916px #fff, 1359px 1641px #fff, 1295px 17px #fff,
    1608px 1407px #fff, 143px 1218px #fff, 1662px 1142px #fff, 659px 777px #fff,
    542px 1351px #fff, 1661px 362px #fff, 1084px 1609px #fff, 354px 621px #fff,
    132px 398px #fff, 1981px 1494px #fff, 428px 1467px #fff, 477px 520px #fff,
    27px 326px #fff, 666px 478px #fff, 1612px 814px #fff, 1168px 1124px #fff,
    1952px 300px #fff, 1344px 1335px #fff, 156px 1428px #fff, 557px 1525px #fff,
    1622px 1585px #fff, 782px 1552px #fff, 1724px 320px #fff, 400px 458px #fff,
    583px 1867px #fff, 1268px 1828px #fff, 1600px 1226px #fff, 420px 1602px #fff,
    849px 698px #fff, 1780px 1621px #fff, 1264px 1423px #fff, 153px 270px #fff,
    1271px 67px #fff, 373px 1112px #fff, 1657px 1210px #fff, 249px 1089px #fff,
    679px 336px #fff, 1890px 255px #fff, 1910px 1007px #fff, 544px 645px #fff,
    1386px 1788px #fff, 192px 404px #fff, 1526px 618px #fff, 504px 1631px #fff,
    538px 64px #fff, 1726px 804px #fff, 1597px 1224px #fff, 704px 369px #fff,
    105px 904px #fff, 1737px 1039px #fff, 1958px 1933px #fff, 1757px 912px #fff,
    450px 970px #fff, 150px 1366px #fff, 453px 1223px #fff, 1491px 577px #fff,
    1026px 1022px #fff, 1320px 1899px #fff, 576px 1433px #fff,
    1121px 1226px #fff, 669px 774px #fff, 1469px 1154px #fff, 1301px 1957px #fff,
    10px 43px #fff, 1835px 1372px #fff, 371px 1258px #fff, 147px 152px #fff,
    447px 819px #fff, 450px 1914px #fff, 1723px 1343px #fff, 1434px 243px #fff,
    758px 1245px #fff, 289px 417px #fff, 1406px 1462px #fff, 1338px 456px #fff,
    895px 248px #fff, 203px 1093px #fff, 937px 783px #fff, 1701px 570px #fff,
    1946px 1285px #fff, 685px 81px #fff, 859px 1312px #fff, 912px 75px #fff,
    1123px 466px #fff, 1749px 1602px #fff, 1203px 1306px #fff, 824px 1822px #fff,
    574px 1913px #fff, 1226px 1974px #fff, 269px 608px #fff, 361px 796px #fff,
    1143px 1567px #fff, 1304px 1136px #fff, 725px 1111px #fff, 1215px 244px #fff,
    341px 270px #fff, 1486px 1241px #fff, 1129px 356px #fff, 1403px 1736px #fff,
    576px 1714px #fff, 1656px 1233px #fff, 159px 1015px #fff, 513px 280px #fff,
    33px 1277px #fff, 1314px 233px #fff, 78px 169px #fff, 1888px 1792px #fff,
    2000px 60px #fff, 1174px 1099px #fff, 390px 344px #fff, 322px 1914px #fff,
    70px 251px #fff, 1175px 1714px #fff, 1540px 1050px #fff, 158px 1324px #fff,
    1966px 707px #fff, 1273px 57px #fff, 1227px 441px #fff, 1985px 1556px #fff,
    1770px 66px #fff, 1255px 241px #fff, 1955px 1562px #fff, 344px 317px #fff,
    1531px 724px #fff, 1399px 857px #fff, 1906px 518px #fff, 1899px 618px #fff,
    83px 1161px #fff, 177px 1347px #fff, 695px 653px #fff, 210px 628px #fff,
    1287px 1846px #fff, 664px 1054px #fff, 1265px 1429px #fff, 694px 287px #fff,
    1244px 1358px #fff, 625px 496px #fff, 1778px 1018px #fff, 1314px 1188px #fff,
    590px 1255px #fff, 310px 1082px #fff, 908px 30px #fff, 861px 360px #fff,
    720px 1122px #fff, 894px 1762px #fff, 1553px 154px #fff, 583px 981px #fff,
    1185px 1997px #fff, 1432px 978px #fff, 984px 216px #fff, 149px 648px #fff,
    94px 449px #fff, 613px 1310px #fff, 500px 749px #fff, 501px 306px #fff,
    1039px 1604px #fff, 1668px 767px #fff, 1882px 613px #fff, 1812px 1961px #fff,
    1289px 522px #fff, 1403px 1026px #fff, 1131px 1465px #fff, 1588px 430px #fff,
    1468px 692px #fff, 1104px 81px #fff, 1528px 681px #fff, 477px 923px #fff,
    667px 425px #fff, 1065px 1255px #fff, 233px 919px #fff, 1087px 567px #fff,
    1839px 1081px #fff, 1684px 815px #fff, 1947px 932px #fff, 802px 1450px #fff,
    1882px 213px #fff, 254px 363px #fff, 1177px 586px #fff, 1072px 382px #fff,
    961px 168px #fff, 1985px 191px #fff, 1982px 829px #fff, 1717px 1152px #fff,
    511px 1181px #fff, 1159px 919px #fff, 769px 1853px #fff, 641px 446px #fff,
    1470px 658px #fff, 1099px 1659px #fff, 227px 122px #fff, 727px 1930px #fff,
    1792px 1248px #fff, 882px 1394px #fff, 235px 443px #fff, 308px 197px #fff,
    179px 1330px #fff, 1663px 1536px #fff, 1612px 1352px #fff, 1385px 508px #fff,
    700px 1116px #fff, 264px 265px #fff, 1113px 1320px #fff, 1395px 901px #fff,
    909px 92px #fff, 1730px 704px #fff, 1237px 1733px #fff, 584px 1873px #fff,
    1632px 1142px #fff, 1794px 864px #fff, 1831px 514px #fff, 460px 15px #fff,
    1005px 521px #fff, 912px 997px #fff, 1410px 484px #fff, 1557px 1649px #fff,
    1526px 1902px #fff, 1147px 1524px #fff, 1297px 960px #fff, 126px 1791px #fff,
    584px 655px #fff, 182px 1719px #fff, 1441px 567px #fff, 883px 256px #fff,
    505px 102px #fff, 1322px 1580px #fff, 1024px 200px #fff, 322px 1800px #fff,
    1786px 1546px #fff, 893px 1883px #fff, 1463px 67px #fff, 200px 246px #fff,
    267px 595px #fff, 725px 1271px #fff, 747px 1871px #fff, 332px 1063px #fff,
    917px 1439px #fff, 962px 688px #fff, 818px 1151px #fff, 1883px 1137px #fff,
    1244px 771px #fff, 1867px 1564px #fff, 1621px 1386px #fff,
    1494px 1345px #fff, 1335px 1215px #fff, 1506px 1863px #fff,
    1754px 143px #fff, 1898px 174px #fff, 1660px 1816px #fff, 560px 445px #fff,
    1411px 1724px #fff, 831px 619px #fff, 438px 1117px #fff, 683px 148px #fff,
    1017px 1572px #fff, 795px 655px #fff, 438px 1325px #fff, 1904px 1821px #fff,
    875px 1818px #fff, 1458px 406px #fff, 1781px 659px #fff, 1366px 1189px #fff,
    424px 562px #fff, 1119px 15px #fff, 553px 848px #fff, 1034px 1326px #fff,
    352px 1425px #fff, 993px 999px #fff, 872px 886px #fff, 1787px 1710px #fff,
    1089px 892px #fff, 1634px 1074px #fff, 844px 1290px #fff, 1027px 836px #fff,
    1437px 1136px #fff, 349px 1852px #fff, 1490px 1157px #fff, 1883px 90px #fff,
    1372px 153px #fff, 1047px 43px #fff, 660px 1997px #fff, 977px 699px #fff,
    129px 1754px #fff, 1027px 846px #fff, 1249px 186px #fff, 253px 1275px #fff,
    568px 1824px #fff, 1027px 77px #fff, 400px 1417px #fff, 1973px 1699px #fff,
    1212px 345px #fff, 1659px 1471px #fff, 1436px 1263px #fff,
    1153px 1757px #fff, 1466px 901px #fff, 1498px 512px #fff, 801px 507px #fff,
    1594px 289px #fff, 199px 769px #fff, 1718px 1312px #fff, 355px 906px #fff,
    1471px 1650px #fff, 1711px 1769px #fff, 590px 684px #fff, 1060px 857px #fff,
    224px 383px #fff, 1087px 616px #fff, 1235px 1992px #fff, 1959px 540px #fff,
    928px 321px #fff, 173px 315px #fff, 249px 1096px #fff, 1325px 1463px #fff,
    830px 1971px #fff, 1476px 234px #fff, 361px 1782px #fff, 1057px 953px #fff,
    1701px 666px #fff, 545px 1513px #fff, 1437px 746px #fff, 1243px 753px #fff,
    1290px 1015px #fff, 1260px 805px #fff, 368px 1012px #fff, 1245px 962px #fff,
    110px 1235px #fff, 419px 1716px #fff, 1372px 649px #fff, 885px 125px #fff,
    468px 1600px #fff, 1837px 1940px #fff, 1693px 1974px #fff, 200px 1375px #fff,
    675px 1316px #fff, 1074px 401px #fff, 115px 537px #fff, 310px 1758px #fff,
    1227px 211px #fff, 1570px 1863px #fff, 647px 1136px #fff, 1158px 528px #fff,
    242px 564px #fff, 1771px 1928px #fff, 418px 104px #fff, 1122px 363px #fff,
    1915px 987px #fff, 76px 1683px #fff, 46px 204px #fff, 986px 1921px #fff,
    639px 1682px #fff, 1948px 511px #fff, 693px 215px #fff, 1101px 1665px #fff,
    1681px 358px #fff, 1670px 74px #fff, 849px 713px #fff, 391px 1850px #fff,
    1876px 1710px #fff, 6px 1082px #fff, 1589px 774px #fff, 1773px 1057px #fff,
    1466px 1122px #fff, 559px 1037px #fff, 1503px 380px #fff, 962px 139px #fff,
    72px 1075px #fff, 405px 24px #fff, 1562px 1559px #fff, 927px 429px #fff,
    297px 416px #fff, 905px 1690px #fff, 636px 8px #fff, 1254px 1267px #fff,
    628px 1258px #fff, 1830px 1775px #fff, 831px 854px #fff, 1775px 1671px #fff,
    1715px 557px #fff, 1976px 1243px #fff, 525px 1737px #fff, 140px 295px #fff,
    1809px 578px #fff, 1490px 534px #fff, 1036px 894px #fff, 1997px 732px #fff,
    95px 70px #fff, 1086px 1243px #fff, 1052px 1025px #fff, 1355px 771px #fff,
    905px 64px #fff, 1836px 101px #fff, 119px 695px #fff, 644px 424px #fff,
    1182px 213px #fff, 231px 1374px #fff, 92px 1937px #fff, 457px 1060px #fff,
    725px 1910px #fff, 601px 1326px #fff, 1861px 569px #fff, 790px 688px #fff,
    7px 926px #fff, 289px 1750px #fff, 1323px 1659px #fff, 66px 252px #fff,
    977px 543px #fff, 1665px 379px #fff, 451px 1509px #fff, 419px 1706px #fff,
    801px 1583px #fff, 630px 340px #fff, 410px 1600px #fff, 1504px 385px #fff,
    1535px 45px #fff, 1847px 1533px #fff, 1933px 611px #fff, 1473px 316px #fff,
    1088px 715px #fff, 741px 1067px #fff, 6px 1468px #fff, 523px 1749px #fff,
    919px 1252px #fff, 171px 162px #fff, 611px 67px #fff, 933px 508px #fff,
    1840px 427px #fff, 106px 650px #fff, 1390px 1965px #fff, 246px 1392px #fff,
    106px 225px #fff, 1476px 1360px #fff, 354px 629px #fff, 1958px 1171px #fff,
    906px 176px #fff, 179px 1476px #fff, 478px 25px #fff, 346px 1575px #fff,
    90px 930px #fff, 433px 1858px #fff, 1714px 689px #fff, 683px 1713px #fff,
    1517px 725px #fff, 1031px 1239px #fff, 229px 1676px #fff, 267px 1274px #fff,
    888px 1553px #fff, 717px 1489px #fff, 97px 1829px #fff, 1974px 862px #fff,
    69px 1372px #fff, 1785px 660px #fff, 260px 1693px #fff, 1080px 119px #fff,
    1851px 1530px #fff, 855px 362px #fff, 160px 1212px #fff, 1581px 1008px #fff,
    444px 850px #fff, 498px 1101px #fff, 1936px 1713px #fff, 1791px 1607px #fff,
    107px 184px #fff, 1491px 1025px #fff, 111px 599px #fff, 315px 1527px #fff,
    1080px 902px #fff, 1080px 1878px #fff, 1678px 6px #fff, 616px 1122px #fff,
    502px 136px #fff, 1205px 1692px #fff, 693px 1443px #fff, 1674px 641px #fff,
    1503px 374px #fff, 291px 1902px #fff, 209px 1935px #fff, 267px 1618px #fff,
    536px 623px #fff, 905px 1374px #fff, 1539px 1962px #fff, 792px 1687px #fff,
    213px 1265px #fff, 1496px 1196px #fff, 812px 1904px #fff, 521px 473px #fff,
    1678px 1380px #fff, 1714px 1159px #fff, 1532px 1128px #fff,
    1038px 626px #fff, 95px 601px #fff, 345px 1494px #fff, 837px 1094px #fff,
    176px 265px #fff, 834px 1028px #fff, 1634px 887px #fff, 708px 1676px #fff,
    595px 1515px #fff, 432px 188px #fff, 696px 517px #fff, 89px 155px #fff,
    454px 1973px #fff, 1965px 1110px #fff, 1714px 1740px #fff, 1724px 973px #fff,
    1210px 840px #fff, 1367px 648px #fff, 999px 235px #fff, 254px 859px #fff,
    1477px 1666px #fff, 1069px 1365px #fff, 716px 700px #fff, 546px 1083px #fff,
    662px 1272px #fff, 1677px 644px #fff, 1570px 1770px #fff, 111px 948px #fff,
    1350px 1054px #fff, 341px 1688px #fff, 812px 1805px #fff, 1941px 1610px #fff,
    935px 332px #fff, 510px 1175px #fff, 1711px 1696px #fff, 1425px 538px #fff,
    1482px 1349px #fff, 224px 963px #fff, 1513px 1911px #fff, 1553px 721px #fff,
    1290px 544px #fff, 124px 175px #fff, 1330px 290px #fff, 1908px 99px #fff,
    1718px 1095px #fff, 1806px 1019px #fff, 1522px 638px #fff,
    1046px 1062px #fff, 1160px 1919px #fff, 1007px 1280px #fff, 436px 124px #fff,
    1647px 394px #fff, 505px 905px #fff, 926px 1968px #fff, 1735px 852px #fff,
    1548px 71px #fff, 928px 1091px #fff, 731px 640px #fff, 119px 1656px #fff,
    969px 543px #fff, 1294px 474px #fff, 1339px 1304px #fff, 137px 1621px #fff,
    1979px 620px #fff, 444px 848px #fff, 245px 1543px #fff, 411px 1813px #fff,
    454px 392px #fff, 542px 1276px #fff, 1396px 1029px #fff, 1725px 257px #fff,
    1661px 1699px #fff, 305px 160px #fff, 1294px 1061px #fff, 1914px 1005px #fff,
    1742px 42px #fff, 1499px 41px #fff, 584px 1462px #fff, 1074px 884px #fff,
    432px 953px #fff, 977px 187px #fff, 93px 1933px #fff, 1799px 1550px #fff,
    230px 149px #fff, 1387px 409px #fff, 273px 1505px #fff, 1100px 251px #fff,
    358px 1850px #fff, 59px 1688px #fff, 1676px 1272px #fff, 28px 276px #fff,
    148px 1632px #fff, 1785px 395px #fff, 1603px 1719px #fff, 289px 1712px #fff,
    1257px 284px #fff, 1782px 532px #fff, 1668px 72px #fff, 202px 1872px #fff,
    1637px 1525px #fff, 983px 325px #fff, 1858px 446px #fff, 104px 1103px #fff,
    1261px 374px #fff, 176px 1353px #fff, 1467px 1164px #fff, 1279px 1993px #fff,
    1288px 1776px #fff, 1800px 1110px #fff, 1526px 30px #fff, 281px 1952px #fff,
    600px 484px #fff, 319px 283px #fff, 1247px 1717px #fff, 1982px 6px #fff,
    1337px 1973px #fff, 788px 1724px #fff, 1023px 1899px #fff, 221px 1309px #fff,
    1148px 531px #fff, 1500px 128px #fff, 1270px 720px #fff, 1121px 1786px #fff,
    1304px 1182px #fff, 1697px 181px #fff, 185px 1089px #fff, 1433px 1798px #fff,
    1063px 960px #fff, 1623px 1875px #fff, 1187px 91px #fff, 636px 21px #fff,
    1908px 804px #fff, 1527px 85px #fff, 1737px 407px #fff, 1734px 1788px #fff,
    909px 1716px #fff, 87px 1862px #fff, 1215px 1812px #fff, 1643px 1825px #fff,
    1139px 1970px #fff, 1970px 359px #fff, 916px 1367px #fff, 151px 1911px #fff,
    653px 206px #fff, 267px 1388px #fff, 1155px 1890px #fff, 1182px 567px #fff,
    555px 445px #fff, 452px 1891px #fff, 1160px 310px #fff, 1448px 1459px #fff,
    1516px 729px #fff, 1664px 1989px #fff, 845px 134px #fff, 1099px 1996px #fff,
    883px 132px #fff, 1054px 1556px #fff, 144px 400px #fff, 1642px 1345px #fff,
    443px 525px #fff, 657px 153px #fff, 521px 1114px #fff, 1205px 141px #fff,
    1883px 132px #fff, 2054px 1556px #fff, 144px 2400px #fff, 2642px 1345px #fff,
    443px 1525px #fff, 657px 1153px #fff, 521px 2114px #fff, 2205px 141px #fff;
  animation: animStar 50s linear infinite;
}
.stars:after {
  content: " ";
  position: absolute;
  top: 100px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 155px 140px #fff, 1382px 1571px #fff, 1470px 1167px #fff,
    1552px 1253px #fff, 1354px 1623px #fff, 958px 922px #fff, 800px 505px #fff,
    1142px 557px #fff, 575px 1146px #fff, 570px 347px #fff, 1140px 1377px #fff,
    1277px 582px #fff, 1299px 1643px #fff, 1422px 384px #fff, 1556px 998px #fff,
    1789px 1505px #fff, 396px 1573px #fff, 325px 888px #fff, 1279px 568px #fff,
    1783px 96px #fff, 798px 1671px #fff, 373px 200px #fff, 440px 1608px #fff,
    1760px 1854px #fff, 1288px 1905px #fff, 1094px 1075px #fff,
    1811px 1734px #fff, 1198px 551px #fff, 1202px 702px #fff, 725px 1493px #fff,
    1394px 1110px #fff, 629px 820px #fff, 983px 530px #fff, 312px 1011px #fff,
    882px 1381px #fff, 16px 305px #fff, 1810px 644px #fff, 721px 18px #fff,
    1553px 1920px #fff, 324px 559px #fff, 1633px 848px #fff, 631px 849px #fff,
    667px 566px #fff, 1710px 213px #fff, 1028px 1152px #fff, 1336px 479px #fff,
    1413px 1600px #fff, 263px 1916px #fff, 1359px 1641px #fff, 1295px 17px #fff,
    1608px 1407px #fff, 143px 1218px #fff, 1662px 1142px #fff, 659px 777px #fff,
    542px 1351px #fff, 1661px 362px #fff, 1084px 1609px #fff, 354px 621px #fff,
    132px 398px #fff, 1981px 1494px #fff, 428px 1467px #fff, 477px 520px #fff,
    27px 326px #fff, 666px 478px #fff, 1612px 814px #fff, 1168px 1124px #fff,
    1952px 300px #fff, 1344px 1335px #fff, 156px 1428px #fff, 557px 1525px #fff,
    1622px 1585px #fff, 782px 1552px #fff, 1724px 320px #fff, 400px 458px #fff,
    583px 1867px #fff, 1268px 1828px #fff, 1600px 1226px #fff, 420px 1602px #fff,
    849px 698px #fff, 1780px 1621px #fff, 1264px 1423px #fff, 153px 270px #fff,
    1271px 67px #fff, 373px 1112px #fff, 1657px 1210px #fff, 249px 1089px #fff,
    679px 336px #fff, 1890px 255px #fff, 1910px 1007px #fff, 544px 645px #fff,
    1386px 1788px #fff, 192px 404px #fff, 1526px 618px #fff, 504px 1631px #fff,
    538px 64px #fff, 1726px 804px #fff, 1597px 1224px #fff, 704px 369px #fff,
    105px 904px #fff, 1737px 1039px #fff, 1958px 1933px #fff, 1757px 912px #fff,
    450px 970px #fff, 150px 1366px #fff, 453px 1223px #fff, 1491px 577px #fff,
    1026px 1022px #fff, 1320px 1899px #fff, 576px 1433px #fff,
    1121px 1226px #fff, 669px 774px #fff, 1469px 1154px #fff, 1301px 1957px #fff,
    10px 43px #fff, 1835px 1372px #fff, 371px 1258px #fff, 147px 152px #fff,
    447px 819px #fff, 450px 1914px #fff, 1723px 1343px #fff, 1434px 243px #fff,
    758px 1245px #fff, 289px 417px #fff, 1406px 1462px #fff, 1338px 456px #fff,
    895px 248px #fff, 203px 1093px #fff, 937px 783px #fff, 1701px 570px #fff,
    1946px 1285px #fff, 685px 81px #fff, 859px 1312px #fff, 912px 75px #fff,
    1123px 466px #fff, 1749px 1602px #fff, 1203px 1306px #fff, 824px 1822px #fff,
    574px 1913px #fff, 1226px 1974px #fff, 269px 608px #fff, 361px 796px #fff,
    1143px 1567px #fff, 1304px 1136px #fff, 725px 1111px #fff, 1215px 244px #fff,
    341px 270px #fff, 1486px 1241px #fff, 1129px 356px #fff, 1403px 1736px #fff,
    576px 1714px #fff, 1656px 1233px #fff, 159px 1015px #fff, 513px 280px #fff,
    33px 1277px #fff, 1314px 233px #fff, 78px 169px #fff, 1888px 1792px #fff,
    2000px 60px #fff, 1174px 1099px #fff, 390px 344px #fff, 322px 1914px #fff,
    70px 251px #fff, 1175px 1714px #fff, 1540px 1050px #fff, 158px 1324px #fff,
    1966px 707px #fff, 1273px 57px #fff, 1227px 441px #fff, 1985px 1556px #fff,
    1770px 66px #fff, 1255px 241px #fff, 1955px 1562px #fff, 344px 317px #fff,
    1531px 724px #fff, 1399px 857px #fff, 1906px 518px #fff, 1899px 618px #fff,
    83px 1161px #fff, 177px 1347px #fff, 695px 653px #fff, 210px 628px #fff,
    1287px 1846px #fff, 664px 1054px #fff, 1265px 1429px #fff, 694px 287px #fff,
    1244px 1358px #fff, 625px 496px #fff, 1778px 1018px #fff, 1314px 1188px #fff,
    590px 1255px #fff, 310px 1082px #fff, 908px 30px #fff, 861px 360px #fff,
    720px 1122px #fff, 894px 1762px #fff, 1553px 154px #fff, 583px 981px #fff,
    1185px 1997px #fff, 1432px 978px #fff, 984px 216px #fff, 149px 648px #fff,
    94px 449px #fff, 613px 1310px #fff, 500px 749px #fff, 501px 306px #fff,
    1039px 1604px #fff, 1668px 767px #fff, 1882px 613px #fff, 1812px 1961px #fff,
    1289px 522px #fff, 1403px 1026px #fff, 1131px 1465px #fff, 1588px 430px #fff,
    1468px 692px #fff, 1104px 81px #fff, 1528px 681px #fff, 477px 923px #fff,
    667px 425px #fff, 1065px 1255px #fff, 233px 919px #fff, 1087px 567px #fff,
    1839px 1081px #fff, 1684px 815px #fff, 1947px 932px #fff, 802px 1450px #fff,
    1882px 213px #fff, 254px 363px #fff, 1177px 586px #fff, 1072px 382px #fff,
    961px 168px #fff, 1985px 191px #fff, 1982px 829px #fff, 1717px 1152px #fff,
    511px 1181px #fff, 1159px 919px #fff, 769px 1853px #fff, 641px 446px #fff,
    1470px 658px #fff, 1099px 1659px #fff, 227px 122px #fff, 727px 1930px #fff,
    1792px 1248px #fff, 882px 1394px #fff, 235px 443px #fff, 308px 197px #fff,
    179px 1330px #fff, 1663px 1536px #fff, 1612px 1352px #fff, 1385px 508px #fff,
    700px 1116px #fff, 264px 265px #fff, 1113px 1320px #fff, 1395px 901px #fff,
    909px 92px #fff, 1730px 704px #fff, 1237px 1733px #fff, 584px 1873px #fff,
    1632px 1142px #fff, 1794px 864px #fff, 1831px 514px #fff, 460px 15px #fff,
    1005px 521px #fff, 912px 997px #fff, 1410px 484px #fff, 1557px 1649px #fff,
    1526px 1902px #fff, 1147px 1524px #fff, 1297px 960px #fff, 126px 1791px #fff,
    584px 655px #fff, 182px 1719px #fff, 1441px 567px #fff, 883px 256px #fff,
    505px 102px #fff, 1322px 1580px #fff, 1024px 200px #fff, 322px 1800px #fff,
    1786px 1546px #fff, 893px 1883px #fff, 1463px 67px #fff, 200px 246px #fff,
    267px 595px #fff, 725px 1271px #fff, 747px 1871px #fff, 332px 1063px #fff,
    917px 1439px #fff, 962px 688px #fff, 818px 1151px #fff, 1883px 1137px #fff,
    1244px 771px #fff, 1867px 1564px #fff, 1621px 1386px #fff,
    1494px 1345px #fff, 1335px 1215px #fff, 1506px 1863px #fff,
    1754px 143px #fff, 1898px 174px #fff, 1660px 1816px #fff, 560px 445px #fff,
    1411px 1724px #fff, 831px 619px #fff, 438px 1117px #fff, 683px 148px #fff,
    1017px 1572px #fff, 795px 655px #fff, 438px 1325px #fff, 1904px 1821px #fff,
    875px 1818px #fff, 1458px 406px #fff, 1781px 659px #fff, 1366px 1189px #fff,
    424px 562px #fff, 1119px 15px #fff, 553px 848px #fff, 1034px 1326px #fff,
    352px 1425px #fff, 993px 999px #fff, 872px 886px #fff, 1787px 1710px #fff,
    1089px 892px #fff, 1634px 1074px #fff, 844px 1290px #fff, 1027px 836px #fff,
    1437px 1136px #fff, 349px 1852px #fff, 1490px 1157px #fff, 1883px 90px #fff,
    1372px 153px #fff, 1047px 43px #fff, 660px 1997px #fff, 977px 699px #fff,
    129px 1754px #fff, 1027px 846px #fff, 1249px 186px #fff, 253px 1275px #fff,
    568px 1824px #fff, 1027px 77px #fff, 400px 1417px #fff, 1973px 1699px #fff,
    1212px 345px #fff, 1659px 1471px #fff, 1436px 1263px #fff,
    1153px 1757px #fff, 1466px 901px #fff, 1498px 512px #fff, 801px 507px #fff,
    1594px 289px #fff, 199px 769px #fff, 1718px 1312px #fff, 355px 906px #fff,
    1471px 1650px #fff, 1711px 1769px #fff, 590px 684px #fff, 1060px 857px #fff,
    224px 383px #fff, 1087px 616px #fff, 1235px 1992px #fff, 1959px 540px #fff,
    928px 321px #fff, 173px 315px #fff, 249px 1096px #fff, 1325px 1463px #fff,
    830px 1971px #fff, 1476px 234px #fff, 361px 1782px #fff, 1057px 953px #fff,
    1701px 666px #fff, 545px 1513px #fff, 1437px 746px #fff, 1243px 753px #fff,
    1290px 1015px #fff, 1260px 805px #fff, 368px 1012px #fff, 1245px 962px #fff,
    110px 1235px #fff, 419px 1716px #fff, 1372px 649px #fff, 885px 125px #fff,
    468px 1600px #fff, 1837px 1940px #fff, 1693px 1974px #fff, 200px 1375px #fff,
    675px 1316px #fff, 1074px 401px #fff, 115px 537px #fff, 310px 1758px #fff,
    1227px 211px #fff, 1570px 1863px #fff, 647px 1136px #fff, 1158px 528px #fff,
    242px 564px #fff, 1771px 1928px #fff, 418px 104px #fff, 1122px 363px #fff,
    1915px 987px #fff, 76px 1683px #fff, 46px 204px #fff, 986px 1921px #fff,
    639px 1682px #fff, 1948px 511px #fff, 693px 215px #fff, 1101px 1665px #fff,
    1681px 358px #fff, 1670px 74px #fff, 849px 713px #fff, 391px 1850px #fff,
    1876px 1710px #fff, 6px 1082px #fff, 1589px 774px #fff, 1773px 1057px #fff,
    1466px 1122px #fff, 559px 1037px #fff, 1503px 380px #fff, 962px 139px #fff,
    72px 1075px #fff, 405px 24px #fff, 1562px 1559px #fff, 927px 429px #fff,
    297px 416px #fff, 905px 1690px #fff, 636px 8px #fff, 1254px 1267px #fff,
    628px 1258px #fff, 1830px 1775px #fff, 831px 854px #fff, 1775px 1671px #fff,
    1715px 557px #fff, 1976px 1243px #fff, 525px 1737px #fff, 140px 295px #fff,
    1809px 578px #fff, 1490px 534px #fff, 1036px 894px #fff, 1997px 732px #fff,
    95px 70px #fff, 1086px 1243px #fff, 1052px 1025px #fff, 1355px 771px #fff,
    905px 64px #fff, 1836px 101px #fff, 119px 695px #fff, 644px 424px #fff,
    1182px 213px #fff, 231px 1374px #fff, 92px 1937px #fff, 457px 1060px #fff,
    725px 1910px #fff, 601px 1326px #fff, 1861px 569px #fff, 790px 688px #fff,
    7px 926px #fff, 289px 1750px #fff, 1323px 1659px #fff, 66px 252px #fff,
    977px 543px #fff, 1665px 379px #fff, 451px 1509px #fff, 419px 1706px #fff,
    801px 1583px #fff, 630px 340px #fff, 410px 1600px #fff, 1504px 385px #fff,
    1535px 45px #fff, 1847px 1533px #fff, 1933px 611px #fff, 1473px 316px #fff,
    1088px 715px #fff, 741px 1067px #fff, 6px 1468px #fff, 523px 1749px #fff,
    919px 1252px #fff, 171px 162px #fff, 611px 67px #fff, 933px 508px #fff,
    1840px 427px #fff, 106px 650px #fff, 1390px 1965px #fff, 246px 1392px #fff,
    106px 225px #fff, 1476px 1360px #fff, 354px 629px #fff, 1958px 1171px #fff,
    906px 176px #fff, 179px 1476px #fff, 478px 25px #fff, 346px 1575px #fff,
    90px 930px #fff, 433px 1858px #fff, 1714px 689px #fff, 683px 1713px #fff,
    1517px 725px #fff, 1031px 1239px #fff, 229px 1676px #fff, 267px 1274px #fff,
    888px 1553px #fff, 717px 1489px #fff, 97px 1829px #fff, 1974px 862px #fff,
    69px 1372px #fff, 1785px 660px #fff, 260px 1693px #fff, 1080px 119px #fff,
    1851px 1530px #fff, 855px 362px #fff, 160px 1212px #fff, 1581px 1008px #fff,
    444px 850px #fff, 498px 1101px #fff, 1936px 1713px #fff, 1791px 1607px #fff,
    107px 184px #fff, 1491px 1025px #fff, 111px 599px #fff, 315px 1527px #fff,
    1080px 902px #fff, 1080px 1878px #fff, 1678px 6px #fff, 616px 1122px #fff,
    502px 136px #fff, 1205px 1692px #fff, 693px 1443px #fff, 1674px 641px #fff,
    1503px 374px #fff, 291px 1902px #fff, 209px 1935px #fff, 267px 1618px #fff,
    536px 623px #fff, 905px 1374px #fff, 1539px 1962px #fff, 792px 1687px #fff,
    213px 1265px #fff, 1496px 1196px #fff, 812px 1904px #fff, 521px 473px #fff,
    1678px 1380px #fff, 1714px 1159px #fff, 1532px 1128px #fff,
    1038px 626px #fff, 95px 601px #fff, 345px 1494px #fff, 837px 1094px #fff,
    176px 265px #fff, 834px 1028px #fff, 1634px 887px #fff, 708px 1676px #fff,
    595px 1515px #fff, 432px 188px #fff, 696px 517px #fff, 89px 155px #fff,
    454px 1973px #fff, 1965px 1110px #fff, 1714px 1740px #fff, 1724px 973px #fff,
    1210px 840px #fff, 1367px 648px #fff, 999px 235px #fff, 254px 859px #fff,
    1477px 1666px #fff, 1069px 1365px #fff, 716px 700px #fff, 546px 1083px #fff,
    662px 1272px #fff, 1677px 644px #fff, 1570px 1770px #fff, 111px 948px #fff,
    1350px 1054px #fff, 341px 1688px #fff, 812px 1805px #fff, 1941px 1610px #fff,
    935px 332px #fff, 510px 1175px #fff, 1711px 1696px #fff, 1425px 538px #fff,
    1482px 1349px #fff, 224px 963px #fff, 1513px 1911px #fff, 1553px 721px #fff,
    1290px 544px #fff, 124px 175px #fff, 1330px 290px #fff, 1908px 99px #fff,
    1718px 1095px #fff, 1806px 1019px #fff, 1522px 638px #fff,
    1046px 1062px #fff, 1160px 1919px #fff, 1007px 1280px #fff, 436px 124px #fff,
    1647px 394px #fff, 505px 905px #fff, 926px 1968px #fff, 1735px 852px #fff,
    1548px 71px #fff, 928px 1091px #fff, 731px 640px #fff, 119px 1656px #fff,
    969px 543px #fff, 1294px 474px #fff, 1339px 1304px #fff, 137px 1621px #fff,
    1979px 620px #fff, 444px 848px #fff, 245px 1543px #fff, 411px 1813px #fff,
    454px 392px #fff, 542px 1276px #fff, 1396px 1029px #fff, 1725px 257px #fff,
    1661px 1699px #fff, 305px 160px #fff, 1294px 1061px #fff, 1914px 1005px #fff,
    1742px 42px #fff, 1499px 41px #fff, 584px 1462px #fff, 1074px 884px #fff,
    432px 953px #fff, 977px 187px #fff, 93px 1933px #fff, 1799px 1550px #fff,
    230px 149px #fff, 1387px 409px #fff, 273px 1505px #fff, 1100px 251px #fff,
    358px 1850px #fff, 59px 1688px #fff, 1676px 1272px #fff, 28px 276px #fff,
    148px 1632px #fff, 1785px 395px #fff, 1603px 1719px #fff, 289px 1712px #fff,
    1257px 284px #fff, 1782px 532px #fff, 1668px 72px #fff, 202px 1872px #fff,
    1637px 1525px #fff, 983px 325px #fff, 1858px 446px #fff, 104px 1103px #fff,
    1261px 374px #fff, 176px 1353px #fff, 1467px 1164px #fff, 1279px 1993px #fff,
    1288px 1776px #fff, 1800px 1110px #fff, 1526px 30px #fff, 281px 1952px #fff,
    600px 484px #fff, 319px 283px #fff, 1247px 1717px #fff, 1982px 6px #fff,
    1337px 1973px #fff, 788px 1724px #fff, 1023px 1899px #fff, 221px 1309px #fff,
    1148px 531px #fff, 1500px 128px #fff, 1270px 720px #fff, 1121px 1786px #fff,
    1304px 1182px #fff, 1697px 181px #fff, 185px 1089px #fff, 1433px 1798px #fff,
    1063px 960px #fff, 1623px 1875px #fff, 1187px 91px #fff, 636px 21px #fff,
    1908px 804px #fff, 1527px 85px #fff, 1737px 407px #fff, 1734px 1788px #fff,
    909px 1716px #fff, 87px 1862px #fff, 1215px 1812px #fff, 1643px 1825px #fff,
    1139px 1970px #fff, 1970px 359px #fff, 916px 1367px #fff, 151px 1911px #fff,
    653px 206px #fff, 267px 1388px #fff, 1155px 1890px #fff, 1182px 567px #fff,
    555px 445px #fff, 452px 1891px #fff, 1160px 310px #fff, 1448px 1459px #fff,
    1516px 729px #fff, 1664px 1989px #fff, 845px 134px #fff, 1099px 1996px #fff,
    883px 132px #fff, 1054px 1556px #fff, 144px 400px #fff, 1642px 1345px #fff,
    443px 525px #fff, 657px 153px #fff, 521px 1114px #fff, 1205px 141px #fff;
}

.stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 925px 817px #fff, 434px 1700px #fff, 1058px 137px #fff,
    1019px 872px #fff, 1352px 1487px #fff, 1145px 127px #fff, 1697px 886px #fff,
    1094px 1207px #fff, 1454px 1408px #fff, 454px 1418px #fff, 1488px 271px #fff,
    1144px 1178px #fff, 945px 364px #fff, 1389px 987px #fff, 893px 1123px #fff,
    736px 1740px #fff, 1133px 37px #fff, 497px 492px #fff, 762px 1160px #fff,
    1803px 757px #fff, 245px 947px #fff, 1962px 156px #fff, 1443px 600px #fff,
    212px 1750px #fff, 308px 954px #fff, 907px 1226px #fff, 886px 522px #fff,
    1283px 474px #fff, 983px 824px #fff, 466px 911px #fff, 550px 1021px #fff,
    1120px 1489px #fff, 410px 1255px #fff, 184px 1529px #fff, 1115px 1478px #fff,
    552px 548px #fff, 595px 788px #fff, 1927px 1280px #fff, 1387px 140px #fff,
    1249px 1845px #fff, 1797px 1115px #fff, 1033px 150px #fff,
    1614px 1084px #fff, 125px 207px #fff, 1115px 1409px #fff, 109px 567px #fff,
    1266px 877px #fff, 585px 1454px #fff, 502px 1231px #fff, 448px 796px #fff,
    493px 1813px #fff, 1810px 726px #fff, 838px 1047px #fff, 939px 816px #fff,
    372px 488px #fff, 119px 409px #fff, 692px 1215px #fff, 754px 558px #fff,
    1971px 1701px #fff, 849px 993px #fff, 567px 9px #fff, 394px 612px #fff,
    1380px 985px #fff, 1367px 1106px #fff, 1001px 1742px #fff,
    1014px 1101px #fff, 1167px 1906px #fff, 551px 319px #fff, 400px 1346px #fff,
    1728px 1715px #fff, 1109px 865px #fff, 825px 1220px #fff, 1363px 1458px #fff,
    1320px 1781px #fff, 98px 1191px #fff, 861px 435px #fff, 1410px 1752px #fff,
    1046px 64px #fff, 1463px 706px #fff, 1796px 1203px #fff, 727px 977px #fff,
    1037px 897px #fff, 1723px 41px #fff, 191px 1979px #fff, 23px 1721px #fff,
    1057px 1357px #fff, 1392px 570px #fff, 1726px 1909px #fff, 193px 1524px #fff,
    1838px 1426px #fff, 737px 64px #fff, 264px 415px #fff, 1114px 1282px #fff,
    23px 1748px #fff, 1052px 466px #fff, 395px 861px #fff, 1880px 263px #fff,
    1px 1754px #fff, 296px 1446px #fff, 506px 691px #fff, 1490px 1775px #fff,
    1235px 1003px #fff, 781px 867px #fff, 291px 1941px #fff, 1483px 1268px #fff,
    322px 707px #fff, 1769px 1465px #fff, 1519px 344px #fff, 747px 345px #fff,
    1377px 1698px #fff, 325px 213px #fff, 1142px 1600px #fff, 1997px 1349px #fff,
    1204px 827px #fff, 1071px 1741px #fff, 1145px 1081px #fff, 378px 1410px #fff,
    245px 1984px #fff, 835px 159px #fff, 504px 1846px #fff, 1931px 1307px #fff,
    781px 952px #fff, 263px 1153px #fff, 927px 1422px #fff, 1477px 675px #fff,
    1991px 1167px #fff, 1641px 905px #fff, 1323px 958px #fff, 934px 176px #fff,
    372px 425px #fff, 892px 138px #fff, 104px 1412px #fff, 1870px 273px #fff,
    547px 173px #fff, 190px 208px #fff, 510px 1221px #fff, 1156px 828px #fff,
    46px 723px #fff, 1385px 1648px #fff, 1902px 973px #fff, 523px 585px #fff,
    525px 1608px #fff, 1762px 1611px #fff, 1223px 239px #fff, 1596px 951px #fff,
    1634px 468px #fff, 1797px 1596px #fff, 1688px 1106px #fff, 1914px 722px #fff,
    761px 1780px #fff, 511px 184px #fff, 1467px 605px #fff, 1967px 846px #fff,
    1855px 475px #fff, 1966px 1922px #fff, 97px 1992px #fff, 908px 593px #fff,
    1881px 424px #fff, 1108px 1742px #fff, 1746px 1945px #fff, 613px 523px #fff,
    160px 348px #fff, 1366px 220px #fff, 124px 1886px #fff, 1550px 226px #fff,
    21px 1569px #fff, 1853px 1329px #fff, 520px 446px #fff, 225px 1256px #fff,
    843px 1000px #fff, 190px 1525px #fff, 758px 120px #fff, 179px 273px #fff,
    204px 1720px #fff, 352px 236px #fff, 1489px 1451px #fff, 1207px 35px #fff,
    1830px 763px #fff, 408px 1500px #fff, 95px 67px #fff, 339px 1028px #fff,
    83px 1490px #fff, 1620px 550px #fff, 754px 472px #fff, 796px 1311px #fff,
    1331px 1503px #fff, 1749px 1023px #fff, 37px 455px #fff, 1492px 392px #fff,
    631px 1049px #fff, 955px 1265px #fff, 403px 82px #fff, 412px 1885px #fff,
    1557px 1292px #fff, 1903px 1454px #fff, 1264px 1px #fff, 1763px 450px #fff,
    579px 653px #fff, 1153px 1534px #fff, 848px 1208px #fff;
  animation: animStar 100s linear infinite;
}
.stars2:after {
  content: " ";
  position: absolute;
  top: 100px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 925px 817px #fff, 434px 1700px #fff, 1058px 137px #fff,
    1019px 872px #fff, 1352px 1487px #fff, 1145px 127px #fff, 1697px 886px #fff,
    1094px 1207px #fff, 1454px 1408px #fff, 454px 1418px #fff, 1488px 271px #fff,
    1144px 1178px #fff, 945px 364px #fff, 1389px 987px #fff, 893px 1123px #fff,
    736px 1740px #fff, 1133px 37px #fff, 497px 492px #fff, 762px 1160px #fff,
    1803px 757px #fff, 245px 947px #fff, 1962px 156px #fff, 1443px 600px #fff,
    212px 1750px #fff, 308px 954px #fff, 907px 1226px #fff, 886px 522px #fff,
    1283px 474px #fff, 983px 824px #fff, 466px 911px #fff, 550px 1021px #fff,
    1120px 1489px #fff, 410px 1255px #fff, 184px 1529px #fff, 1115px 1478px #fff,
    552px 548px #fff, 595px 788px #fff, 1927px 1280px #fff, 1387px 140px #fff,
    1249px 1845px #fff, 1797px 1115px #fff, 1033px 150px #fff,
    1614px 1084px #fff, 125px 207px #fff, 1115px 1409px #fff, 109px 567px #fff,
    1266px 877px #fff, 585px 1454px #fff, 502px 1231px #fff, 448px 796px #fff,
    493px 1813px #fff, 1810px 726px #fff, 838px 1047px #fff, 939px 816px #fff,
    372px 488px #fff, 119px 409px #fff, 692px 1215px #fff, 754px 558px #fff,
    1971px 1701px #fff, 849px 993px #fff, 567px 9px #fff, 394px 612px #fff,
    1380px 985px #fff, 1367px 1106px #fff, 1001px 1742px #fff,
    1014px 1101px #fff, 1167px 1906px #fff, 551px 319px #fff, 400px 1346px #fff,
    1728px 1715px #fff, 1109px 865px #fff, 825px 1220px #fff, 1363px 1458px #fff,
    1320px 1781px #fff, 98px 1191px #fff, 861px 435px #fff, 1410px 1752px #fff,
    1046px 64px #fff, 1463px 706px #fff, 1796px 1203px #fff, 727px 977px #fff,
    1037px 897px #fff, 1723px 41px #fff, 191px 1979px #fff, 23px 1721px #fff,
    1057px 1357px #fff, 1392px 570px #fff, 1726px 1909px #fff, 193px 1524px #fff,
    1838px 1426px #fff, 737px 64px #fff, 264px 415px #fff, 1114px 1282px #fff,
    23px 1748px #fff, 1052px 466px #fff, 395px 861px #fff, 1880px 263px #fff,
    1px 1754px #fff, 296px 1446px #fff, 506px 691px #fff, 1490px 1775px #fff,
    1235px 1003px #fff, 781px 867px #fff, 291px 1941px #fff, 1483px 1268px #fff,
    322px 707px #fff, 1769px 1465px #fff, 1519px 344px #fff, 747px 345px #fff,
    1377px 1698px #fff, 325px 213px #fff, 1142px 1600px #fff, 1997px 1349px #fff,
    1204px 827px #fff, 1071px 1741px #fff, 1145px 1081px #fff, 378px 1410px #fff,
    245px 1984px #fff, 835px 159px #fff, 504px 1846px #fff, 1931px 1307px #fff,
    781px 952px #fff, 263px 1153px #fff, 927px 1422px #fff, 1477px 675px #fff,
    1991px 1167px #fff, 1641px 905px #fff, 1323px 958px #fff, 934px 176px #fff,
    372px 425px #fff, 892px 138px #fff, 104px 1412px #fff, 1870px 273px #fff,
    547px 173px #fff, 190px 208px #fff, 510px 1221px #fff, 1156px 828px #fff,
    46px 723px #fff, 1385px 1648px #fff, 1902px 973px #fff, 523px 585px #fff,
    525px 1608px #fff, 1762px 1611px #fff, 1223px 239px #fff, 1596px 951px #fff,
    1634px 468px #fff, 1797px 1596px #fff, 1688px 1106px #fff, 1914px 722px #fff,
    761px 1780px #fff, 511px 184px #fff, 1467px 605px #fff, 1967px 846px #fff,
    1855px 475px #fff, 1966px 1922px #fff, 97px 1992px #fff, 908px 593px #fff,
    1881px 424px #fff, 1108px 1742px #fff, 1746px 1945px #fff, 613px 523px #fff,
    160px 348px #fff, 1366px 220px #fff, 124px 1886px #fff, 1550px 226px #fff,
    21px 1569px #fff, 1853px 1329px #fff, 520px 446px #fff, 225px 1256px #fff,
    843px 1000px #fff, 190px 1525px #fff, 758px 120px #fff, 179px 273px #fff,
    204px 1720px #fff, 352px 236px #fff, 1489px 1451px #fff, 1207px 35px #fff,
    1830px 763px #fff, 408px 1500px #fff, 95px 67px #fff, 339px 1028px #fff,
    83px 1490px #fff, 1620px 550px #fff, 754px 472px #fff, 796px 1311px #fff,
    1331px 1503px #fff, 1749px 1023px #fff, 37px 455px #fff, 1492px 392px #fff,
    631px 1049px #fff, 955px 1265px #fff, 403px 82px #fff, 412px 1885px #fff,
    1557px 1292px #fff, 1903px 1454px #fff, 1264px 1px #fff, 1763px 450px #fff,
    579px 653px #fff, 1153px 1534px #fff, 848px 1208px #fff;
}

.stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 967px 1545px #fff, 587px 754px #fff, 1087px 1592px #fff,
    576px 985px #fff, 178px 787px #fff, 461px 879px #fff, 558px 422px #fff,
    475px 1963px #fff, 753px 737px #fff, 1596px 438px #fff, 436px 725px #fff,
    121px 1878px #fff, 956px 824px #fff, 1462px 825px #fff, 1336px 1512px #fff,
    879px 1760px #fff, 740px 1846px #fff, 1808px 1290px #fff, 1481px 1412px #fff,
    857px 1257px #fff, 1087px 1558px #fff, 606px 1445px #fff, 127px 669px #fff,
    1114px 479px #fff, 1186px 1225px #fff, 41px 143px #fff, 366px 508px #fff,
    970px 1195px #fff, 1824px 1247px #fff, 1451px 1936px #fff, 1564px 449px #fff,
    1535px 986px #fff, 1317px 706px #fff, 1823px 283px #fff, 823px 38px #fff,
    366px 372px #fff, 1705px 1752px #fff, 1775px 320px #fff, 1396px 634px #fff,
    35px 301px #fff, 552px 986px #fff, 364px 1626px #fff, 1662px 1899px #fff,
    1544px 1884px #fff, 1098px 1494px #fff, 1030px 645px #fff, 250px 564px #fff,
    396px 431px #fff, 539px 862px #fff, 839px 254px #fff, 516px 1752px #fff,
    805px 1678px #fff, 446px 1757px #fff, 1901px 559px #fff, 722px 229px #fff,
    643px 1616px #fff, 1366px 420px #fff, 1358px 1930px #fff, 120px 1136px #fff,
    1951px 775px #fff, 1530px 647px #fff, 1847px 1948px #fff, 1265px 1692px #fff,
    50px 1204px #fff, 807px 1899px #fff, 504px 1421px #fff, 1000px 1023px #fff,
    1062px 915px #fff, 308px 1744px #fff, 1284px 1814px #fff, 1841px 1246px #fff,
    1046px 46px #fff, 1923px 1577px #fff, 1418px 1431px #fff, 664px 360px #fff,
    755px 167px #fff, 1759px 711px #fff, 616px 710px #fff, 1560px 1787px #fff,
    143px 552px #fff, 1049px 1880px #fff, 963px 469px #fff, 1145px 1942px #fff,
    410px 1650px #fff, 1876px 393px #fff, 386px 1156px #fff, 145px 797px #fff,
    1156px 1788px #fff, 29px 392px #fff, 96px 1629px #fff, 23px 1905px #fff,
    296px 310px #fff, 1676px 1856px #fff, 274px 1216px #fff, 363px 1720px #fff,
    330px 1552px #fff, 1576px 1103px #fff, 1938px 406px #fff, 1121px 851px #fff,
    1955px 1543px #fff;
  animation: animStar 150s linear infinite;
}
.stars3:after {
  content: " ";
  position: absolute;
  top: 100px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 967px 1545px #fff, 587px 754px #fff, 1087px 1592px #fff,
    576px 985px #fff, 178px 787px #fff, 461px 879px #fff, 558px 422px #fff,
    475px 1963px #fff, 753px 737px #fff, 1596px 438px #fff, 436px 725px #fff,
    121px 1878px #fff, 956px 824px #fff, 1462px 825px #fff, 1336px 1512px #fff,
    879px 1760px #fff, 740px 1846px #fff, 1808px 1290px #fff, 1481px 1412px #fff,
    857px 1257px #fff, 1087px 1558px #fff, 606px 1445px #fff, 127px 669px #fff,
    1114px 479px #fff, 1186px 1225px #fff, 41px 143px #fff, 366px 508px #fff,
    970px 1195px #fff, 1824px 1247px #fff, 1451px 1936px #fff, 1564px 449px #fff,
    1535px 986px #fff, 1317px 706px #fff, 1823px 283px #fff, 823px 38px #fff,
    366px 372px #fff, 1705px 1752px #fff, 1775px 320px #fff, 1396px 634px #fff,
    35px 301px #fff, 552px 986px #fff, 364px 1626px #fff, 1662px 1899px #fff,
    1544px 1884px #fff, 1098px 1494px #fff, 1030px 645px #fff, 250px 564px #fff,
    396px 431px #fff, 539px 862px #fff, 839px 254px #fff, 516px 1752px #fff,
    805px 1678px #fff, 446px 1757px #fff, 1901px 559px #fff, 722px 229px #fff,
    643px 1616px #fff, 1366px 420px #fff, 1358px 1930px #fff, 120px 1136px #fff,
    1951px 775px #fff, 1530px 647px #fff, 1847px 1948px #fff, 1265px 1692px #fff,
    50px 1204px #fff, 807px 1899px #fff, 504px 1421px #fff, 1000px 1023px #fff,
    1062px 915px #fff, 308px 1744px #fff, 1284px 1814px #fff, 1841px 1246px #fff,
    1046px 46px #fff, 1923px 1577px #fff, 1418px 1431px #fff, 664px 360px #fff,
    755px 167px #fff, 1759px 711px #fff, 616px 710px #fff, 1560px 1787px #fff,
    143px 552px #fff, 1049px 1880px #fff, 963px 469px #fff, 1145px 1942px #fff,
    410px 1650px #fff, 1876px 393px #fff, 386px 1156px #fff, 145px 797px #fff,
    1156px 1788px #fff, 29px 392px #fff, 96px 1629px #fff, 23px 1905px #fff,
    296px 310px #fff, 1676px 1856px #fff, 274px 1216px #fff, 363px 1720px #fff,
    330px 1552px #fff, 1576px 1103px #fff, 1938px 406px #fff, 1121px 851px #fff,
    1955px 1543px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(-2000px);
  }
}
