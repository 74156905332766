.header {
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.headerLogo {
  font-family: "GmarketSansBold";
  font-size: 24px;
  text-align: center;
  width: 200px;
  color: #fff;
}
