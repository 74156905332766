.wrap {
  position: relative;
  width: 100%;
  height: 1600px;
  background-color: #eee;
  overflow: hidden;
}

.info {
  position: absolute;
  width: 254px;
  height: 96px;
  margin-left: 150px;
  top: 150px;
}

.info img {
  width: 254px;
  height: 96px;
}

.frame {
  position: absolute;
  width: 1500px;
  height: 800px;
  background-color: skyblue;
  top: 360px;
  left: 50%;
  transform: translateX(-50%);
}
